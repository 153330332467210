@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Mulish", sans-serif;
	scroll-behavior: smooth;
	overflow-x: hidden;	
}

button {
	font-family: "Inter", sans-serif;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

.flow-date input {
	background: transparent;
	border: none;
}
.heroBgImage {
	background-image: url("./assets/hero-circle-sm.png");
}
.footerBgImage {
	background-image: url("./assets/footerbg-sm.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom;
}
.footer-link a {
	color: #fff;
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: transparent !important;
	border: 2px solid #fff !important; /* Border for inactive state */
	border-radius: 50%;
	opacity: 1;
	margin: 0 5px;
	cursor: pointer;
}

.swiper-pagination-bullet-active {
	background-color: #fff !important;
	opacity: 1;
}

.mySwiper {
	height: 100% !important;
	width: 100%;
}

.hideNavigation .swiper-button-prev,
.hideNavigation .swiper-button-next {
	display: none;
}
.showNavigation .swiper-button-prev,
.showNavigation .swiper-button-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 35px !important;
	height: 35px !important;
	background-color: #353942;
	border: none;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff !important;
}

.showNavigation .swiper-button-prev::after,
.showNavigation .swiper-button-next::after {
	font-size: 1rem !important;
}
.showNavigation .swiper-button-prev:hover,
.showNavigation .swiper-button-next:hover {
	background-color: #353942;
	color: #fff;
}

.showNavigation .swiper-button-prev {
	left: 10px;
}

.showNavigation .swiper-button-next {
	right: 10px;
}

.css-13cymwt-control,
.css-b62m3t-container {
	border: none !important;
	background: transparent !important;
	width: 100%;
}
.css-qbdosj-Input {
	width: 100%;
}
.css-qbdosj-Input:focus,
.css-13cymwt-control:focus,
.css-b62m3t-container:focus {
	outline: hidden;
	border: none !important;
	background: transparent !important;
}

/* .css-1nmdiq5-menu,
#react-select-99-listbox, .css-1n6sfyn-MenuList {
	position: relative;
	z-index: 9999;
	background: #ffffff;	
} */

.css-1u9des2-indicatorSeparator {
	display: none !important;
}

.css-13cymwt-control {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}
.css-13cymwt-control:focus {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}
.css-13cymwt-control:hover {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}

input #react-select-27-input:focus {
	border: none !important;
	outline: none !important;
}

@media (min-width: 1024px) {
	.heroBgImage2 {
		background-image: url("./assets/hero-circle.png");
	}
	.footerBgImage {
		background-image: url("./assets/footerbg-lg.png");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: right;
	}
}

@media screen and (min-width: 375px){
	.review-img {
		background: url('./assets/reviews-header.png');
		/* background-repeat: repeat; */
		background-repeat: repeat;
        object-fit: fill;
        background-size: cover;		
		background-position: center;
		/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
	}
}

@media screen and ((max-width: 375px)) {
	.review-img {
		background: url('./assets/reviews-header-md.png');
		background-repeat: no-repeat;
		background-position: center;
        object-fit: fill;
        background-size: cover;	
		
	}
}